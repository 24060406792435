import React from "react"
import aboutData from "Src/data/about.json"
import Layout from "../components/Containers/Layout/Layout"
import SEO from "../components/seo" 
import BasicText from "../components/Sections/BasicText/BasicText"
// import EventList from "../components/Sections/EventList/EventList"
// import ImageGrid from "../components/Sections/ImageGrid/ImageGrid"

const AboutPage = () => {
  const about = aboutData[0].textSection
  // const events = aboutData[0].dateSection
  // const images = aboutData[0].imageSection

  return (
    <Layout>
      <SEO title="About" />
      <BasicText content={about} />
      {/* <EventList
        headline={events.title}
        dates={events.dates}
      />
      <ImageGrid
        headline={images.title}
        images={images.images}
      /> */}
    </Layout>
  )
}

export default AboutPage
