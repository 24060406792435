/* eslint-disable react/no-array-index-key */
import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import path from "path"
import Button from "UI/Button/Button.jsx"
import Img from "gatsby-image"

import "./BasicText.scss"

const BasicText = (props) => {
  const { content, image } = props

  return (
    <div className="basic-text-section container pt-2 pb-3 pb-md-5 border-top">
      {content.length && (
        content.map((item, index) => {
          const { title, text, link } = item

          return (
            <div key={title} className={classNames("row", index > 0 ? "mt-2 mt-md-3" : "mt-2")}>
              <div className="col-md">
                <h2 className="mb-2 mb-md-0">{title}</h2>
              </div>
              <div className="col-md">
                {image && (
                  <Img fluid={image.childImageSharp.fluid} />
                )}
                <div dangerouslySetInnerHTML={{ __html: text }} />
                {
                  link && (
                    <a
                      className="d-flex mt-2"
                      rel="noopener noreferrer"
                      target="_blank"
                      href={path.resolve(`./assets/${link}`)}
                      download={link}
                    >
                      <Button
                        classes="btn btn-border"
                        text="Download"
                        realButton={false}
                      />
                    </a>
                  )
                }
                
              </div>
            </div>
          )
        })
      )}
    </div>
  )
}

BasicText.propTypes = {
  content: PropTypes.instanceOf(Object)
}

BasicText.defaultProps = {
  content: {}
}

export default BasicText
