import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Icon from 'UI/Icon/Icon.jsx';
import "./Button.scss";

const Button = (props) => {

  const {
    externalLink,
    classes,
    realButton,
    onClick,
    text,
    icon,
    iconRotation,
    download,
  } = props;

  return (
    <>
      {
        externalLink && download && !icon && (
          <a
            href={externalLink}
            className={classNames(classes, "btn")}
            rel="noopener noreferrer"
            target="_blank"
            download={download}
          >
            {text && text}
          </a>
        )
      }
      {
        externalLink && !download && (
          <a
            href={externalLink}
            className={classNames(classes, "btn")}
            rel="noopener noreferrer"
            target="_blank"
          >
            {text && text}
          </a>
        )
      }
      {
        realButton && (
          <button
            type="submit"
            className={classNames(classes, "btn")}
            tabIndex={0}
          >
            {text && text}
          </button>
        )
      }
      {
        icon && !download && (
          <button
            type="submit"
            className={classNames(classes, "btn")}
            tabIndex={0}
            onClick={onClick}
          >
            {text && text}
            <Icon
              iconType={icon}
              rotation={iconRotation}
            />
          </button>
        )
      }
      {
        icon && download && (
          <a
            href={externalLink}
            className={classNames(classes, "btn")}
            rel="noopener noreferrer"
            target="_blank"
            download={download}
          >
            {text && text}
            <Icon
              iconType={icon}
              rotation={iconRotation}
            />
          </a>
        )
      }
      {
        !externalLink && !realButton && !icon && (
          <div
            onClick={onClick}
            onKeyDown={onClick}
            className={classNames(classes, "btn")}
            role="button"
            tabIndex={0}
          >
            {text && text}
          </div>
        )
      }
    </>
  );
}

Button.propTypes = {
  externalLink: PropTypes.string,
  classes: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  iconRotation: PropTypes.string,
  realButton: PropTypes.bool,
  onClick: PropTypes.func
};

Button.defaultProps = {
  externalLink: "",
  classes: "",
  realButton: false
};

export default Button
